import { defineStore } from 'pinia';

const appProgrammeStore = defineStore('appProgrammeStore', {
  state: () => ({
    userProgrammes: [],
  }),
  getters: {
    getProgrammes(state) {
      return state.userProgrammes;
    },
  },
  actions: {
    setUserProgrammes(programmes) {
      this.userProgrammes = programmes;
    },
    clear() {
      this.userProgrammes = [];
    },
  },
});

export default appProgrammeStore;
