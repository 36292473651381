<template>
  <li class="otj-dropdown" :class="{ 'is-open': isOpen }" ref="otjDropdown">
    <button type="button" class="btn-icon otj-dropdown-btn" aria-label="OTJ" title="OTJ" @click="toggleOpen">
      <i class="fas fa-clock"></i>
      <span>O.T.J</span>
      <i class="fas fa-caret-down"></i>
    </button>
    <ul class="dropdown" :class="{ 'is-open': isOpen }">
      <li class="otj-dropdown-item">
        <a>Quick Add</a>
      </li>
      <li class="otj-dropdown-item">
        <router-link :to="{ name: 'programme_otj_logs' }" title="OTJ Logs">
          <i class="fas fa-clock"></i>
          <span>O.T.J. Logs</span>
        </router-link>
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    toggleOpen() {
      if (this.isOpen) {
        this.$emit('close');
      } else {
        this.$emit('open');
      }
    },
    handleClickOutside(event) {
      const otjDropdown = this.$refs.otjDropdown;
      if (otjDropdown && !otjDropdown.contains(event.target)) {
        this.$emit('close');
      }
    },
  },
};
</script>
