<template>
  <div id="programme-schedule" class="content-with-spacing">
    <div class="programme-header" v-if="availableProgrammesReady">
      <div class="title-container">
        <h1 v-if="programmeDetails">
          <i class="fas fa-chart-bar"></i> {{ programmeDetails.name }}
        </h1>
      </div>
      <div v-if="availableProgrammes.length > 1" class="header-dropdown">
        <span class="dropdown-label">Programmes:</span>
        <k-dropdown class="programme-dropdown" :options="sortedProgrammes" v-model="programmeIdFromDropdown"></k-dropdown>
      </div>
    </div>
    <div v-if="scheduleReady" class="calendar-container panel panel-default">
      <k-calendar class="calendar-content"
        :schedule="scheduledEvents"
        :existingIcalSubscriptionId="icalSubscriptionId"
        :existingIcalSubscriptionUrl="icalSubscriptionUrl"
      >
      </k-calendar>
    </div>
  </div>
</template>

<style scoped>
.programme-header {
  display: flex;
}

.programme-header .header-dropdown {
  display: flex;
  align-items: center;
  gap: 15px;
}

.calendar-content {
  padding: 15px;
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import StorageMixin from '@mixins/storage-mixins';
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import KDropdown from '@base-components/k-dropdown.vue';
import KCalendar from '@base-components/k_calendar/k-calendar.vue';
import { sortObjectArray } from '@utils/sort-by-object-property';

export default {
  components: {
    KDropdown,
    KCalendar,
  },

  mixins: [TimeMixin, StorageMixin, ErrorMixin, PageReadyMixin],

  data() {
    return {
      availableProgrammes: [],
      availableProgrammesReady: false,
      schedule: [],
      scheduleReady: false,
      icalSubscriptionId: undefined,
      icalSubscriptionUrl: undefined,
      programmeIdFromDropdown: undefined,
      initialRouteResolutionComplete: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getAvailableProgrammes();
  },

  watch: {
    availableProgrammesReady() {
      if (this.availableProgrammesReady) {
        this.$logger.info('Available programmes ready, resolving programme ID');
        this.resolveProgrammeId();
        this.initialRouteResolutionComplete = true;
      }
    },
    programmeIdFromDropdown: {
      handler() {
        this.saveCurrentProgramme(this.programmeIdFromDropdown);
        if (this.programmeIdFromDropdown !== this.programmeIdFromRoute) {
          this.$router.replace({ name: 'programme_schedule', params: { programmeId: this.programmeIdFromDropdown } });
        } else {
          // Programme ID has been set by the route so retrieve the schedule
          this.getUserProgrammeSchedule();
        }
      },
      deep: true, // required for this to trigger during resolveProgrammeId
    },
    programmeIdFromRoute() {
      if (this.initialRouteResolutionComplete && this.$route.name === 'programme_schedule' && !this.programmeIdFromRoute) {
        // After initial load, if there is ever no programme ID in the route, re-resolve the programmeId
        this.$logger.info('No programme ID in route, re-resolving');
        this.resolveProgrammeId();
      } else {
        this.getUserProgrammeSchedule();
      }
    },
  },

  computed: {
    programmeIdFromRoute() {
      if (this.$route.params.programmeId === undefined || Number.isNaN(Number(this.$route.params.programmeId))) {
        return undefined;
      }
      return Number(this.$route.params.programmeId);
    },
    programmeDetails() {
      return this.availableProgrammes.filter(x => x.id === this.programmeIdFromRoute)[0];
    },
    sortedProgrammes() {
      return sortObjectArray(this.availableProgrammes.slice(), 'name', false);
    },
    scheduledEvents() {
      return this.schedule.filter(x => x.start_time).map(x => ({
        title: x.asset_name || x.module_name,
        start: this.parseTimestamp(x.start_time, undefined, 'yyyy-MM-dd HH:mm'),
        end: this.parseTimestamp(x.end_time, undefined, 'yyyy-MM-dd HH:mm'),
        id: `${x.event_type}_${x.module_asset_id || x.module_id}`,
        module_id: x.module_id,
        event_type: x.event_type,
        classNames: [x.event_type],
        extendedProps: {
          module_asset_id: x.module_asset_id,
          module_name: x.module_name,
          module_release_date: x.module_release_date,
          is_dot_event: this.isDotEvent(x),
          module_expected_completion_date: x.module_expected_completion_date,
        },
      }));
    },
  },

  methods: {
    resolveProgrammeId() {
      this.programmeIdFromDropdown = undefined;
      this.$logger.info('Resolving programme ID from route or store', undefined, true);
      const newProgrammeId = this.programmeIdFromRoute || this.loadCurrentProgramme();
      if (newProgrammeId && !this.availableProgrammes.find(x => x.id === newProgrammeId)) {
        this.clearCurrentProgramme();
        this.$router.push({ name: '404' });
        return;
      }
      if (!newProgrammeId) {
        if (this.availableProgrammes.length > 0) {
          this.$logger.info('No programme ID found - defaulting to first available programme');
          this.programmeIdFromDropdown = this.availableProgrammes[0].id;
        } else {
          this.$logger.info('User is not a member of any programme');
          this.clearCurrentProgramme();
          this.$router.push('/user_not_in_programme');
        }
      } else {
        this.$logger.info('Programme ID resolved - updating programme ID in dropdown', { newProgrammeId });
        this.programmeIdFromDropdown = newProgrammeId;
      }
    },
    isDotEvent(ev) {
      return (this.parseTimestamp(ev.start_time, undefined, 'yyyy-MM-dd')
      === this.parseTimestamp(ev.end_time, undefined, 'yyyy-MM-dd') || ev.event_type === 'meeting') || !ev.end_time;
    },
    getAvailableProgrammes() {
      this.availableProgrammesReady = false;
      this.$logger.info('Getting available programmes');
      return this.$http.get('/api/curriculum/programmes')
        .then(response => {
          this.availableProgrammes = response.data.programmes;
          this.$logger.info('Got available programmes', undefined);
        })
        .catch(err => {
          this.showError(err, true);
        }).then(() => {
          this.availableProgrammesReady = true;
          this.$Loading.finish();
        });
    },
    getUserProgrammeSchedule() {
      this.$Loading.start();
      this.scheduleReady = false;
      this.$logger.info('Getting programme schedule');
      this.$http.get(`/api/curriculum/programmes/${this.programmeIdFromRoute}/schedule`)
        .then(response => {
          this.schedule = response.data.schedule;
          this.icalSubscriptionId = response.data.ical_subscription_id;
          this.icalSubscriptionUrl = response.data.ical_url;
          this.$logger.info('Got programme schedule', undefined);
        })
        .catch(err => {
          this.schedule = [];
          this.icalSubscriptionId = null;
          this.icalSubscriptionUrl = null;
          this.$logger.autowarn('Error getting user programme schedule', { programmeId: this.programmeIdFromRoute }, err);
          this.$ktoast.info('No schedule found for this programme');
        }).then(() => {
          this.scheduleReady = true;
          this.$Loading.finish();
        });
    },
    saveCurrentProgramme(programmeId) {
      this.saveSetting('programme_page', 'currentProgramme', programmeId);
    },
    loadCurrentProgramme() {
      return this.loadSetting('programme_page', 'currentProgramme');
    },
    clearCurrentProgramme() {
      this.saveSetting('programme_page', 'currentProgramme', undefined);
    },
  },
};
</script>
