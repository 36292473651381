<template>
  <div>
    <div class="panel panel-default" v-if="extraPointsResults.length">
      <div class="row summary-panel k-feedback-test-block">
        <h4 class="evaluation-title feedback-panel-title">
          <i class="fas fa-code"></i> Score
        </h4>
      </div>
      <div class="extra-results">
        <div>
        <ul>
          <li class="summary-list" v-for="entry in extraPointsResults">
            <div class="function-details">
              <span class="function-name" v-html="getMarkdown(entry.test_description)"></span>
              <br/>
            </div>
            <div class="btn-link">
              <span class="extra-points-score"><b>{{formatScore(entry.score)}}</b></span>
            </div>
          </li>
        </ul>
      </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.function-details {
  margin: 0;
  flex: 1 1 50%;
  color: var(--kate-primary);
  padding: 10px;
}

.extra-points-score {
  font-size: 1.2em;
  padding-left: 10px;
  color: var(--kate-logo-lime-green);
}

.extra-results {
  display: block;
  width: 100%;
  padding: 0 15px 15px;
}

ul {
  padding-left: 0;
}

h4.evaluation-title {
  padding: 15px;
  width: 100%;
}

.panel-default {
  padding: 10px;
}

.summary-list {
  list-style: none;
  border-bottom: 1px solid var(--kate-type-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summary-list .btn-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 8px 0;
}
</style>

<script>
import format from 'number-format.js';
import { markdown } from '@utils/maths-markdown';

export default {
  props: {
    extraPointsResults: {
      type: Array,
      default: () => [],
    },
    precision: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
    };
  },

  methods: {
    getMarkdown(source) {
      return markdown(source);
    },
    formatScore(num) {
      return format(`0.${'0'.repeat(this.precision)}`, num);
    },
  },
};
</script>
