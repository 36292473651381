<template>
  <div id="knowledge-base" v-if="ready">
    <div class="kb-header" v-if="isDashboard">
      <h1>Knowledge Base Overview</h1>
      <div><button @click="downloadInfo()" class="btn btn-outlined download">Download</button></div>
    </div>
    <div v-if="threads && threads.length > 0">
        <div class="toolbar-row">
          <div v-if="isApp" class="action-buttons">
            <button @click="openModal" class="btn btn-primary">Ask a question <i class="fas fa-plus"></i></button>
          </div>
        </div>
        <div class="threads-container">
          <div class="thread-container-header">
            <div class="thread-count-header">
              <span>Displaying</span>
              <k-dropdown class="items-per-page-dropdown" v-if="displayItemsDropdown" placeholder="" :show-search="false" :options="numberOfItemsDisplayedOptions" v-model="maxItemsPerPage"></k-dropdown>
              <b v-else>{{ paginatedEntries.length }}</b>
              <span>out of <b>{{ orderedThreads.length }} threads</b></span>
            </div>
            <div v-if="isDashboard" class="filters">
              <k-filters :default-settings = "defaultSettings"
                :filter-options="filterOptions"
                @filter="updateFilters"
              >
              </k-filters>
            </div>
          </div>
          <div>
            <thread-card v-for="thread in paginatedEntries"
              :key="thread.id"
              :thread="thread">
            </thread-card>
          </div>
        </div>
      <pagination v-if="numOfPages" v-model="currentPage" :count="numOfPages" :total-entries="orderedThreads.length" :displayed-entries="paginatedEntries.length"></pagination>
    </div>
    <div class="empty-placeholder" v-else>
      <p>No questions posted yet. Be the first.</p>
      <div class="action-buttons">
        <button v-if="isApp" @click="openModal" class="btn btn-primary">Ask a question <i class="fas fa-plus"></i></button>
      </div>
    </div>
    <add-question
      v-if="isApp"
      :show="toggleModal"
      @added="handleNewQuestion"
      @close="closeModal"
    >
    </add-question>
  </div>
</template>

<style>
#knowledge-base .panel-pagination {
  padding: 0 15px 15px;
}

</style>

<style scoped>
.kb-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toolbar-row,
.filters {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.filters .disabled {
  pointer-events: none;
}

.filters .disabled label {
  text-decoration: line-through;
}

.toolbar-row .filters button {
  background-color: transparent;
  border: var(--border-primary);
  color: var(--kate-type-light);
  padding: 12px;
}

.toolbar-row .filters button:hover {
  background-color: var(--kate-button-outlined-hover);
  color: var(--kate-type-dark);
}

.toolbar-row .filters button.active {
  background-color: var(--kate-button-outlined-hover);
  color: var(--kate-type-dark);
}

.threads-container {
  margin-top: 15px;
}

.thread-container-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.thread-count-header {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.thread-count-header b {
  color: var(--kate-type-light);
}
</style>

<script>
import useGlobalStore from '@stores/global';
import CsvMixin from '@mixins/csv-mixins';
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import KDropdown from '@base-components/k-dropdown.vue';
import Pagination from '@base-components/k-pagination.vue';
import KFilters from '@base-components/k-filters.vue';
import { sortObjectArray } from '@utils/sort-by-object-property';
import ThreadCard from './thread-card.vue';
import AddQuestion from './add-question.vue';

export default {
  components: {
    ThreadCard,
    AddQuestion,
    KDropdown,
    Pagination,
    KFilters,
  },

  mixins: [CsvMixin, ErrorMixin, PageReadyMixin],

  props: {
    sidebarThreads: {
      type: Array,
    },
  },

  data() {
    return {
      store: useGlobalStore(),
      ready: false,
      toggleModal: false,
      threads: [],
      active: 0,
      hideAnswered: false,
      hideClosed: true,
      hideDiscussion: true,
      hidePublic: false,
      hidePrivate: false,
      hideAwaitingAnswer: false,
      hide_bug_reports: true,
      currentPage: 0,
      currentIndex: 0,
      dashboardRoute: { name: 'knowledge_base_overview', query: {} },
      maxItemsPerPage: 10,
      activeFilters: undefined,
      defaultSettings: {
        hide_answered: true,
        hide_public: false,
        hide_private: false,
        hide_awaiting_answer: false,
        hide_not_assigned: false,
        hide_discussions: true,
        hide_closed_threads: true,
        hide_bug_reports: true,
      },
      filterOptions: [
        {
          text: 'Hide answered threads',
          func: filter => filter.accepted_id === null || filter.accepted_id === undefined,
          key: 'hide_answered',
        },
        {
          text: 'Hide public threads',
          func: filter => filter.is_private === true,
          key: 'hide_public',
        },
        {
          text: 'Hide private threads',
          func: filter => filter.is_private === false || filter.is_private === undefined,
          key: 'hide_private',
        },
        {
          text: 'Hide threads awaiting answer',
          func: filter => !filter.awaiting_op_answer,
          key: 'hide_awaiting_answer',
        },
        {
          text: 'Hide threads not assigned to you',
          func: filter => filter.assign_to === this.store.userId,
          key: 'hide_not_assigned',
        },
        {
          text: 'Hide closed threads',
          func: filter => !filter.is_closed,
          key: 'hide_closed_threads',
        },
        {
          text: 'Hide discussions',
          func: filter => !filter.is_discussion,
          key: 'hide_discussions',
        },
        {
          text: 'Hide bug reports',
          func: filter => !filter.is_bug_report,
          key: 'hide_bug_reports',
        },
      ],
    };
  },

  beforeMount() {
    this.$Loading.start();
    if (this.isDashboard) {
      this.getThreads();
    }
  },

  mounted() {
    if (this.isApp) {
      this.threads = this.sidebarThreads;
      this.filterThreads = this.sidebarThreads;
    }
  },

  watch: {
    sidebarThreads() {
      this.threads = this.sidebarThreads;
    },

    sidebarReady() {
      if (this.isApp) {
        this.ready = this.sidebarReady;
      }
    },

    numOfPages() {
      this.currentPage = 0;
    },
  },

  computed: {
    sidebarReady() {
      return this.$sidebar.ready;
    },

    orderedThreads() {
      if (!this.ready) {
        return [];
      }
      return this.getSortedBy('posted_on', this.filteredThreads);
    },

    filteredThreads() {
      if (this.isDashboard && this.activeFilters) {
        return this.threads.filter(this.activeFilters);
      }
      return this.threads;
    },

    moduleId() {
      return this.$route.params.moduleId;
    },

    isDashboard() {
      return this.store.appName === 'dashboard';
    },

    isApp() {
      return this.store.appName === 'app';
    },

    threadsEndpoint() {
      if (this.isApp) {
        return `/api/curriculum/knowledge/${this.moduleId}/threads`;
      }
      return '/api/curriculum/knowledge/threads?is_public=false';
    },

    /* Pagination */
    numOfPages() {
      if (this.orderedThreads.length <= this.maxItemsPerPage) {
        return undefined;
      }
      return Math.ceil(this.orderedThreads.length / this.maxItemsPerPage);
    },

    paginatedEntries() {
      if (!this.numOfPages) {
        return this.orderedThreads;
      }
      const startingIndex = this.maxItemsPerPage * this.currentPage;
      const endingIndex = Math.min(this.maxItemsPerPage + startingIndex, this.orderedThreads.length);
      return this.orderedThreads.slice(startingIndex, endingIndex);
    },

    // To avoid having "showing 20 out of 12 entries", limit to the max number of entries.
    numberOfItemsDisplayedOptions() {
      const maxPagination = 50;
      return [10, 20, maxPagination].filter(x => x < this.orderedThreads.length).concat(this.orderedThreads.length < maxPagination ? [this.orderedThreads.length] : []);
    },

    displayItemsDropdown() {
      if (this.orderedThreads.length > this.numberOfItemsDisplayedOptions[0]) {
        return true;
      }
      return false;
    },
  },

  methods: {
    downloadInfo() {
      this.$http.get('/api/curriculum/knowledge/threads/info').then(result => {
        this.downloadCsvFromTable(
          {
            answered_by: {},
            answered_on: {},
            asked_by: {},
            assigned_to: {},
            awaiting_answer_from_learner: {},
            is_private: {},
            is_closed: {},
            is_discussion: {},
            is_bug_report: {},
            module_name: {},
            posted_on: {},
            programme_name: {},
            question_id: {},
          },
          result.data.threads,
          'info-activity.csv',
        );
      }).catch(err => {
        this.$logger.autowarn('Error retrieving threads', undefined, err);
        this.showError(err);
      }).then(() => {
        this.ready = true;
      });
    },

    getThreads() {
      this.ready = false;
      this.$http.get(this.threadsEndpoint).then(result => {
        this.threads = result.data.threads;
      }).catch(err => {
        this.$logger.autowarn('Error retrieving threads', undefined, err);
        this.showError(err);
      }).then(() => {
        this.ready = true;
      });
    },

    handleNewQuestion() {
      if (this.isDashboard) {
        this.getThreads();
      } else {
        this.$emit('refresh');
      }
    },

    getSortedBy(key, arr) {
      return sortObjectArray(arr, key, true);
    },

    // Misc
    openModal() {
      this.toggleModal = true;
    },

    closeModal() {
      this.toggleModal = false;
    },

    updateFilters(filters) {
      this.activeFilters = filters;
    },
  },
};
</script>
