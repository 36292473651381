import useGlobalStore from '@stores/global';
import useFeatureFlagStore from '@stores/feature-flag';
import useThemeStore from '@stores/theme';
import appProgrammeStore from '@stores/app-programme-store';
import ErrorMixin from './error-mixins';

export default {
  mixins: [ErrorMixin],

  data() {
    return {
      store: useGlobalStore(),
      themeStore: useThemeStore(),
      featureFlagStore: useFeatureFlagStore(),
      programmeStore: appProgrammeStore(),
    };
  },

  methods: {
    goHome() {
      if (this.store.appName === 'dashboard' && this.store.isEmployer) {
        this.$router.push({ name: 'employer_learner_summary' });
      } else {
        const progPage = this.store.appName === 'dashboard' ? 'monitor_programme_summary' : 'programme_page';
        if (this.$route.query.programmeId) {
          this.$router.push({ name: progPage, params: { programmeId: this.$route.query.programmeId } });
        } else {
          this.$router.push({ path: '/' });
        }
      }
    },
    logIn() {
      this.$logger.info('Logging in user', undefined, true);
      this.$http.get('/api/profile/user').then(response => {
        this.featureFlagStore.loadUser(response.data.id, response.data.email);
        this.store.logIn(response.data);
        this.themeStore.resolveThemePreference(response.data);
        this.$logger.info('Logged in user');
        const promises = [];

        if (this.store.appName === 'app') {
          promises.push(this.getUserProgrammes());
        }
        return Promise.all(promises).then(() => response.data);
      }).then(() => {
        if (this.store.appName === 'app' && !this.store.termsAccepted) {
          this.$router.push({ name: 'policy_accept_page' });
        } else if (this.$route.query.next) {
          const path = decodeURIComponent(this.$route.query.next);
          this.$router.push({ path });
        } else {
          this.goHome();
        }
      }).catch(err => {
        this.store.logOut();
        this.$logger.error('Error logging in user', undefined, err);
        this.showError(err, true);
      });
    },
    getUserProgrammes() {
      this.$logger.info('Getting available programmes for the learner');
      return this.$http.get('/api/curriculum/programmes')
        .then(res => {
          this.programmeStore.setUserProgrammes(res.data.programmes);
          this.$logger.info('Got available programmes');
        })
        .catch(err => {
          this.$logger.error('Could not fetch programmes data for the user', err);
          this.showError(err, true);
          return Promise.reject(err);
        });
    },
  },
};
