import { sortObjectArray } from '../utils/sort-by-object-property';
import useGlobalStore from '../stores/global';
import StorageMixin from './storage-mixins';

export default {
  mixins: [StorageMixin],

  data() {
    return {
      store: useGlobalStore(),
      availableProgrammes: [],
      availableProgrammesReady: false,
      programmeIdFromDropdown: undefined,
      initialRouteResolutionComplete: false,
    };
  },

  beforeMount() {
    this.getAvailableProgrammes();
  },

  watch: {
    availableProgrammesReady() {
      if (this.availableProgrammesReady) {
        this.$logger.info('Available programmes ready, resolving programme ID');
        this.resolveProgrammeId();
        this.initialRouteResolutionComplete = true;
      }
    },
    programmeIdFromDropdown: {
      handler(newVal) {
        if (!newVal) return;

        this.saveCurrentProgramme(newVal);
        if (newVal !== this.programmeIdFromRoute) {
          this.$router.replace({
            name: this.routeName,
            params: { programmeId: newVal },
          });
        } else {
          this.onProgrammeSelected();
        }
      },
      deep: true,
    },
    programmeIdFromRoute() {
      if (this.initialRouteResolutionComplete && this.$route.name === this.routeName && !this.programmeIdFromRoute) {
        this.$logger.info('No programme ID in route, re-resolving');
        this.resolveProgrammeId();
      } else {
        this.onProgrammeSelected();
      }
    },
  },

  computed: {
    programmeIdFromRoute() {
      const id = this.$route.params.programmeId;
      if (id === undefined || Number.isNaN(Number(id))) {
        return undefined;
      }
      return Number(id);
    },
    programmeDetails() {
      return this.availableProgrammes.filter(x => x.id === this.programmeIdFromRoute)[0];
    },
    sortedProgrammes() {
      return sortObjectArray(this.availableProgrammes.slice(), 'name', false);
    },
  },

  methods: {
    resolveProgrammeId() {
      this.programmeIdFromDropdown = undefined;
      this.$logger.info('Resolving programme ID from route or store', undefined, true);
      const newProgrammeId = this.programmeIdFromRoute || this.loadCurrentProgramme();
      if (newProgrammeId && !this.availableProgrammes.find(x => x.id === newProgrammeId)) {
        this.clearCurrentProgramme();
        this.$router.push({ name: '404' });
        return;
      }
      if (!newProgrammeId) {
        if (this.availableProgrammes.length > 0) {
          this.$logger.info('No programme ID found - defaulting to first available programme');
          this.programmeIdFromDropdown = this.availableProgrammes[0].id;
        } else {
          this.$logger.info('User is not a member of any programme');
          this.clearCurrentProgramme();
          this.$router.push('/user_not_in_programme');
        }
      } else {
        this.$logger.info('Programme ID resolved - updating programme ID in dropdown', { newProgrammeId });
        this.programmeIdFromDropdown = newProgrammeId;
      }
    },

    getAvailableProgrammes() {
      this.$Loading.start();
      this.availableProgrammesReady = false;
      return this.$http.get('/api/curriculum/programmes')
        .then(response => {
          // console.log('API Response:', response.data);
          this.availableProgrammes = response.data.programmes;
          // console.log('Updated availableProgrammes:', this.availableProgrammes);
          this.$logger.info('Got available programmes', undefined);
        })
        .catch(err => {
          // console.log('Error:', err);
          this.showError(err, true);
        }).then(() => {
          this.availableProgrammesReady = true;
          this.$Loading.finish();
        });
    },

    saveCurrentProgramme(programmeId) {
      this.saveSetting('programme_page', 'currentProgramme', programmeId);
    },

    loadCurrentProgramme() {
      return this.loadSetting('programme_page', 'currentProgramme');
    },

    clearCurrentProgramme() {
      this.saveSetting('programme_page', 'currentProgramme', undefined);
    },

    // This method should be implemented by components using this mixin
    onProgrammeSelected() {
      throw new Error('Components using programme-selection-mixin must implement onProgrammeSelected method');
    },
  },
};
