<template>
  <div v-if="quizMeta">
    <form @submit.prevent="submitQuiz">
      <k-quiz-question
        ref="question" v-for="que in orderedQuestions" :key="que.id"
        :question="que"
        :previous-answer="getPreviousAnswer(que.id)"
        :correct="isQuestionCorrect(que.id)"
        :read-only="readOnly"
        :show-result="showResult"
        @update="updateAnswer(que.id, $event)"
      ></k-quiz-question>
      <div v-if="!(showResult || readOnly)" class="submit-form-button">
        <button @click.prevent="submitQuiz" class="btn btn-primary submit-button" title="Submit Answers">Submit answers</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
.submit-form-button {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 15px 0;
}

.submit-form-button button {
  margin: 0 auto;
  display: block;
  padding: 10px 25px;
}

</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import { sortObjectArray } from '@utils/sort-by-object-property';
import getOrNull from '@utils/get-or-null';
import copyObject from '@utils/copy-object';
import KQuizQuestion from './k-quiz-question.vue';

export default {
  components: {
    'k-quiz-question': KQuizQuestion,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    quizMeta: {
      type: Object,
    },
    latestQuizSubmission: {
      type: Object,
    },
    previousAnswers: {
      type: Array,
      default: () => undefined,
    },
    showResult: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      studentAnswers: {},
    };
  },

  beforeMount() {
    this.loadPreviousSave();
  },

  computed: {
    relevantQuizMeta() {
      if (this.showResult && this.latestQuizSubmission && this.latestQuizSubmission.quiz_json) {
        // If displaying results from the latest submission, look at the quiz data from that submission
        return this.latestQuizSubmission.quiz_json;
      }
      // Otherwise use the current quiz data
      return this.quizMeta;
    },
    orderedQuestions() {
      return sortObjectArray(this.relevantQuizMeta.questions, 'number');
    },
  },

  methods: {
    loadPreviousSave() {
      if (!getOrNull('selected_answers', this.latestQuizSubmission)) {
        return;
      }
      for (let i = 0; i < this.latestQuizSubmission.selected_answers.length; i++) {
        const ans = this.latestQuizSubmission.selected_answers[i];
        this.studentAnswers[ans.question_id] = ans.answers;
      }
    },
    submitQuiz() {
      this.$emit('submit', this.createPayload());
    },
    updateAnswer(questionId, answers) {
      this.studentAnswers[questionId] = answers;
      this.$emit('submit', this.createPayload(), true);
    },
    createPayload() {
      return {
        selected_answers: Object.entries(copyObject(this.studentAnswers)).map(x => ({
          question_id: parseInt(x[0], 10),
          answers: x[1],
        })),
      };
    },
    getPreviousAnswer(questionId) {
      if (!this.previousAnswers) {
        return undefined;
      }
      for (let i = 0; i < this.previousAnswers.length; i++) {
        if (this.previousAnswers[i].question_id === questionId) {
          return this.previousAnswers[i].answers;
        }
      }
      return undefined;
    },
    isQuestionCorrect(questionId) {
      if (this.latestQuizSubmission && this.latestQuizSubmission.feedback) {
        const evaluation = this.latestQuizSubmission.feedback.evaluation;
        for (let i = 0; i < evaluation.length; i++) {
          if (evaluation[i].question_id === questionId) {
            return Boolean(evaluation[i].score);
          }
        }
      }
      return false;
    },
    clearAnswers() {
      this.$refs.question.map(q => q.clearAnswer());
    },
  },
};
</script>
