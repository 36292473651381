/* globals ENVIRONMENT */
/* eslint no-param-reassign: "off" */
import { FliptEvaluationClient } from '@flipt-io/flipt-client-browser';
import useFeatureFlagStore from '@stores/feature-flag';
import stringMatch from '@utils/string-match';

class KFeatureFlags {
  constructor(logger) {
    this.logger = logger;
    this.store = useFeatureFlagStore();
    this.client = null;
  }

  isWebAssemblyError(err) {
    this.logger.debug('Checking for WebAssembly error', { err });
    return (err instanceof EvalError || err?.name === 'CompileError') && stringMatch(err.message, 'WebAssembly');
  }

  getClient() {
    // First argument to the init is the Flipt namespace - this matches the KATE environment
    const namespace = ENVIRONMENT === 'local' ? 'dev' : ENVIRONMENT;
    return FliptEvaluationClient.init(namespace, {
      url: '/api/flipt',
    }).then(client => {
      this.logger.info('Flipt client initialized successfully');
      this.client = client;
    }).catch(err => {
      let logFunc = this.logger.error;
      let logMsg = 'Failed to initialize Flipt client - all feature flags will evaluate to false';
      const logPayload = { browser: navigator.userAgent };
      if (this.isWebAssemblyError(err)) {
        logFunc = this.logger.warn;
        logMsg += ' - this browser may treat the `wasm-unsafe-eval` directive in the CSP differently,';
        logMsg += ' causing it to block the WebAssembly code in the Flipt client from running';
      }
      logFunc(logMsg, logPayload, err, true);
    });
  }

  isEnabled(flagKey, from) {
    if (!this.client) {
      this.logger.warn('Evaluation failed - Flipt client is not initialized', { flagKey, from });
      return false;
    }
    // Just doing boolean flags for now
    if (!this.store.entityId) {
      this.logger.warn('Feature flag evaluation requires an entityId to be set', { flagKey, from });
      return false;
    }
    const logPayload = {
      entityId: this.store.entityId, evaluationContext: this.store.evaluationContext, flagKey, from,
    };
    this.logger.info(`Evaluating flag "${flagKey}"`, logPayload);
    const res = this.client.evaluateBoolean(flagKey, this.store.entityId, this.store.evaluationContext);
    // This returns an object with a bunch of meta, not just boolean value
    if (res.status !== 'success') {
      this.logger.warn(`Error evaluating flag "${flagKey}": ${res.error_message}`, { response: res, ...logPayload });
      return false;
    }
    this.logger.info(`Flag evaluation success on flag "${flagKey}": flag is ${res.result.enabled ? 'enabled' : 'disabled'} by ${res.result.reason}`, { response: res, ...logPayload });
    return res.result.enabled;
  }
}

export default {
  install(app) {
    const ff = new KFeatureFlags(app.config.globalProperties.$logger);
    app.config.globalProperties.$featureFlags = ff;
  },
};
