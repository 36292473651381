import axios from 'axios';
import getOrNull from '@utils/get-or-null';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong';

function getErrorMessage(err, hide) {
  if (getOrNull('response.data.err', err) === 'maintenance') {
    return getOrNull('response.data.msg', err);
  }
  if (getOrNull('response.data.msg', err) && !hide) {
    return getOrNull('response.data.msg', err);
  }
  return hide ? DEFAULT_ERROR_MESSAGE : err;
}

export default {
  methods: {
    showError(err, hide, goAway) {
      // Use hide to show only the default error message - mainly for app
      if (axios.isCancel(err)) {
        return;
      }
      const msg = getErrorMessage(err, hide);
      this.$ktoast.warning(`${msg}`, goAway ? { goAway } : { goAway: 2500 });
    },
  },
};
