<template>
  <div class="explanation-section">
    <div class="explanation-header">
      <img :src="chatbotImage" class="chatbot-icon" alt="KATE bot avatar"/>
      <div class="title-section">
        <h4 class="explanation-title">KATE's Explanation <span class="beta-badge">BETA</span></h4>
        <p class="beta-disclaimer">This feature is in beta and under active development. Feedback helps us improve!</p>
      </div>
    </div>
    <div class="explanation-container">
      <div v-if="isLoading" class="explanation-loading">
        <i class="fas fa-spinner fa-spin"></i> Getting explanation...
      </div>
      <div v-else-if="error" class="explanation-error">
        <i class="fas fa-exclamation-circle"></i> {{error}}
      </div>
      <div v-else-if="explanation" class="explanation-content" v-html="sanitizedExplanation">
      </div>
      <div v-if="explanation" class="rating-container">
        <button class="upvote"
            @click="updateRating(true)"
            :disabled="isRatingDisabled"
            :class="rating ? 'upvoted': ''"
            aria-label="Upvote button"
            title="Upvote button">
          <i class="fas fa-thumbs-up"></i>
        </button>
        <button class="downvote"
            @click="updateRating(false)"
            :disabled="isRatingDisabled"
            :class="rating === false ? 'downvoted': ''"
            aria-label="downvote button"
            title="downvote button">
          <i class="fas fa-thumbs-down"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.explanation-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--kate-border-color);
}

.explanation-container {
  min-height: 100px;
  overflow-y: auto;
  background: var(--kate-chat);
  border: var(--kate-chat-border);
  border-radius: 12px;
  padding: 15px;
}

.explanation-header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 15px;
}

.title-section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.explanation-title {
  margin: 0;
  color: var(--kate-type-primary);
  display: flex;
  align-items: center;
  gap: 8px;
}

.beta-badge {
  font-size: 0.7em;
  background-color: var(--kate-primary);
  color: var(--kate-type-light);
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: normal;
  text-transform: uppercase;
}

.beta-disclaimer {
  margin: 0;
  font-size: 0.8em;
  color: var(--kate-type-light);
  line-height: 1.2;
}

.explanation-loading,
.explanation-error {
  text-align: center;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.explanation-error {
  color: var(--kate-danger);
}

.explanation-content {
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
}

.chatbot-icon {
  width: 30px;
  height: 30px;
}

.rating-container {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 10px;
  padding-right: 10px;
}

.rating-container button {
  background-color: var(--kate-background);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: 1px solid;
  padding: 6px;
}

.rating-container button.upvote {
  color: var(--kate-success-alt);
  border-color: var(--kate-success-alt);
}

.rating-container button.upvote:hover,
.rating-container button.upvote.upvoted,
.rating-container button.upvote.upvoted:hover {
  color: var(--kate-type-success);
  border-color: var(--kate-type-success);
}

.rating-container button.downvote {
  color: var(--kate-danger-alt);
  border-color: var(--kate-danger-alt);
}

.rating-container button.downvote:hover,
.rating-container button.downvote.downvoted,
.rating-container button.downvote.downvoted:hover {
  color: var(--kate-danger);
  border-color: var(--kate-danger);
}

.rating-container button:disabled,
.rating-container button:disabled:hover {
  color: var(--kate-disabled);
  border-color: var(--kate-disabled);
  background-color: var(--kate-background);
}
</style>

<script>
import { importImage } from '@utils/dynamic-image-importer';
import { markdown } from '@utils/maths-markdown';

export default {
  name: 'FeedbackExplanation',

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
    explanation: {
      type: String,
      default: '',
    },
    rating: {
      type: Boolean,
      default: null,
    },
    isRatingDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update-rating'],

  computed: {
    chatbotImage() {
      return importImage('icons/kate-bot-chat-colours.svg');
    },
    sanitizedExplanation() {
      return this.$kpurify.sanitise(markdown(this.explanation));
    },
  },

  methods: {
    updateRating(value) {
      if (!this.isRatingDisabled) {
        this.$emit('update-rating', value);
      }
    },
  },
};
</script>
