import routes from '@kate-routes';
import useGlobalStore from '@stores/global';
import getRoutesWithProperty from '@utils/get-routes-with-property';

class PermissionsHandler {
  constructor() {
    this.store = useGlobalStore();
    this.dashboardPagePermissions = Object.fromEntries(
      getRoutesWithProperty(routes, 'kate_permissions', true)
        .map(({ name, kate_permissions: katePermissions }) => [name, katePermissions]),
    );
  }

  hasPermission(permission) {
    if (this.store && this.store.userPermissions) {
      return this.store.userPermissions.includes(permission);
    }
    return false;
  }

  hasDashboardPagePermission(pageName) {
    const pagePerm = this.dashboardPagePermissions[pageName];
    if (!pagePerm) {
      return true;
    }
    if (this.store && this.store.userPermissions) {
      return this.store.userPermissions.some(perm => pagePerm.includes(perm));
    }
    return false;
  }
}

export default {
  install(app) {
    const handler = new PermissionsHandler();
    app.config.globalProperties.$permissions = handler; // eslint-disable-line no-param-reassign
  },
};
